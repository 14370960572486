import Model from './Model'

export default class ConstructionSiteRegistrationModel extends Model {
  id!: number
  project_id!: number
  project!: { id:number, name:string, reference:string }
  company_id!: number
  company!: { id:number, name:string, reference:string }
  construction_worker_id!: number
  construction_worker!: { id:number, firstname:string, lastname:string, date_of_birth:string }
  created_at!: string

  resource () : string {
    return 'constructionSiteRegistrations'
  }
}
